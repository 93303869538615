import { Priority, registerApp } from '@mfl/framework';
import { navigationItems } from '@mfe/core-navigation';
import { currentUser, openUpgradeModal } from '@mfl/platform-shell';
import {
  CAMPAIGNS_ENTITLEMENT,
  CAMPAIGNS_MANAGE_PERMISSION,
} from '@msl/campaign-gateway-sdk';

import { baseRoute } from './constants';
import strings from './campaigns.strings';

const CAMPAIGNS_KEY = 'CAMPAIGNS';
const CAMPAIGNS_ICON = 'megaphone';
const PERSONAL_SIGNATURES_MODE = 'personal-signatures';

export async function register() {
  if (
    currentUser.domainId === PERSONAL_SIGNATURES_MODE ||
    !currentUser.isAllowedTo(CAMPAIGNS_MANAGE_PERMISSION)
  )
    return;

  if (currentUser.isEntitledTo(CAMPAIGNS_ENTITLEMENT)) {
    await registerApp('campaigns', baseRoute);

    navigationItems.register(
      {
        key: CAMPAIGNS_KEY,
        text: strings.campaignsNavTitle,
        fontAwesomeIcon: CAMPAIGNS_ICON,
        route: baseRoute,
      },
      { priority: Priority.Medium - 50 }
    );

    return;
  }

  // If no permission - show pro badge and open upgrade modal
  navigationItems.register(
    {
      key: CAMPAIGNS_KEY,
      text: strings.campaignsNavTitle,
      fontAwesomeIcon: CAMPAIGNS_ICON,
      ornaments: [{ type: 'pro' }],
      handler: () => openUpgradeModal(),
    },
    { priority: Priority.Medium - 50 }
  );
}
